

h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
    color: #3A5E74!important;
}

a{
    text-decoration: none;
    color: #3A5E74!important;
}

.active button{
    background-color: #3A5E74!important;
    color: #fff!important;
}